import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

export default function TermsPage() {
  return (
    <Wrapper>
      <div className="constraint">
        <div>
          <h1>服務聲明</h1>
          <p>
            理想印制 Li-Xiang Print
            電子商務網站，隨著市場環境的改變本公司將會不時修訂網站政策，
          </p>
          <p>
            理想印保留隨時修改會員服務使用條款及各該使用規範或約定之權利，修正後的條款將刊登於網站上。
          </p>
          <p>
            請會員自行詳加閱讀，不另外個別通知使用者，如果對於理想印制網站隱私權聲明、
          </p>
          <p>或與個人資料有關之相關事項有任何疑問，請與理想印制客服聯絡。</p>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 40px 0;
  & .constraint {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 0 auto;
    padding: 0px 20px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;
